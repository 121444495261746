/* General Styling */
.home-page {
  padding: 10px;
}

.main-content {
  margin-top: 20px;
}

/* About MAAC Section */
.bg1 {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.centered-text {
  font-family: 'Conflate', sans-serif;
  font-size: 3vw;
  text-align: center;
  color: #ffd505;
  margin: 10px 0;
}

.about-maac {
  font-size: 2vw;
  line-height: 1.15em;
  margin-bottom: 2vw;
  letter-spacing: 0.5px;
  color: black;
  text-align: center;
}

.row1,
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 5vw;
  text-align: center;
}

/* Placements Section */
.placements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 20px 0;
}

.placements-content {
  flex: 1;
  max-width: 50%;
}

.placements h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.placements p {
  font-size: 16px;
  color: #666;
}

.companies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  max-width: 50%;
}

.companies img {
  width: calc(33% - 10px);
  margin: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 4px;
}

.companies img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Courses Section */
.courses {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 20px;
  margin: 20px 0;
}

.courses-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.course-item {
  flex: 1 1 calc(30% - 40px);
  margin: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.course-video img,
.course-video video {
  width: 100%;
  height: auto;
  display: block;
}

.course-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  text-align: center;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.course-item:hover .course-content {
  transform: translateY(0);
}

.course-content h2 {
  font-size: 18px;
  margin-bottom: 10px;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.course-item:hover .course-content h2 {
  transform: translateY(0);
}

.course-content p {
  font-size: 14px;
  display: none;
}

.course-item:hover .course-content p {
  display: block;
}

.bottom {
  text-align: center; /* Center the image horizontally */
  margin: 20px 0; /* Add some margin around the image */
}

.bottom img {
  max-width: 90%; /* Ensure the image scales down with the screen size */
  height: auto; /* Maintain the aspect ratio of the image */
  display: block; /* Remove any inline spacing issues */
  margin-left: auto; /* Center the image */
  margin-right: auto; /* Center the image */
}

/* How It Works Section */
.how-it-works {
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.how-it-text {
  margin-bottom: 40px;
}

.how {
  background: #f4f4f4;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
}

.step-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.step-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rev-row {
  flex-direction: row-reverse;
}

.step-number {
  font-size: 72px;
  font-weight: bold;
  color: #fde96d;
}

.step-content {
  flex: 1;
}

.step-image {
  flex: 1;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .home-page {
    padding: 5px;
  }
  .step-row,
  .rev-row {
    flex-direction: column;
  }

  .step-number {
    margin-bottom: 10px;
  }

  .step-content {
    text-align: center;
  }

  .placements {
    flex-direction: column;
  }

  .placements-content,
  .companies {
    max-width: 100%;
  }

  .companies img {
    width: calc(50% - 10px);
  }

  .row1,
  .row {
    padding: 0 2vw;
  }

  .centered-text {
    font-size: 4vw;
  }

  .about-maac {
    font-size: 3vw;
  }

  .bottom img {
    max-width: 80%; /* Adjust the image size for tablets */
    margin: 15px 0; /* Adjust margin for tablets */
  }

}

@media (max-width: 480px) {
  .home-page {
    padding: 2px;
  }

  .bottom img {
    max-width: 90%; /* Adjust the image size for mobile devices */
    margin: 10px 0; /* Adjust margin for mobile devices */
  }

  .course-item {
    flex: 1 1 100%;
  }

  .companies img {
    width: 100%;
  }

  .centered-text {
    font-size: 5vw;
  }

  .about-maac {
    font-size: 4vw;
  }
}
