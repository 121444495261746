.parallax-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6e0808;
  background-image: url('../images/background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.parallax-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-svg-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.enquiry-form {
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  padding: 2em;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 0 25px 25px;
}

.svg-container {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 600px;
  margin: 25px;
}

.svg-container img {
  width: 100%;
  height: auto;
}

.form-row {
  margin-bottom: 1em;
}

.form-row label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 18px;
}

.form-row input,
.form-row select {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-row button {
  width: 100%;
  padding: 0.75em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-row button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 0.875em;
  margin-top: 0.5em;
}

@media (max-width: 768px) {
  .form-svg-container {
    flex-direction: column;
  }

  .enquiry-form {
    max-width: 300px;
    padding: 1em;
    margin: 25px auto;
  }

  .svg-container {
    max-width: 300px;
    margin: 25px auto;
  }
}

@media (max-width: 480px) {
  .enquiry-form {
    max-width: 280px;
    padding: 1em;
  }

  .svg-container {
    max-width: 280px;
  }
  .svg-container img {
    width: 100%;
    height: 38vh;
  }

  .form-row label {
    font-size: 16px;
  }

  .form-row input,
  .form-row select {
    padding: 0.4em;
  }

  .form-row button {
    padding: 0.6em;
  }
}
