.side-navbar {
  position: fixed;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  border-radius: 0 5px 5px 0;
}

.side-navbar a {
  color: white;
  background: rgb(237, 174, 21);
  text-decoration: none;
  margin: 2px;
  transition: transform 0.2s;
  padding: 10px;
}

.side-navbar a:hover {
  transform: scale(1.5);
}

.icon {
  font-size: 24px;
}
