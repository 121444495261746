.slider {
  position: relative;
  width: 100%;
  height: 500vh; /* Use viewport height to make it responsive */
  max-height: 500px; /* Maximum height for larger screens */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 1s ease, opacity 1s ease;
  opacity: 0;
  transform: scale(0.8);
}

.slide.active {
  opacity: 1;
  transform: scale(1);
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Optional: To give a dark overlay on images */
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

@media (max-width: 768px) {
  .slider {
    height: 40vh; /* Adjust height for tablets and smaller screens */
  }
}

@media (max-width: 480px) {
  
  .slider {
    height: 20vh; /* Adjust height for mobile devices */
  }
}
