/* General navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s, opacity 0.3s, transform 0.3s;
}

.navbar.scrolled {
  background-color: rgba(1, 0, 0, 0.916);
  opacity: 0.9;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar:hover {
  background-color: black;
  opacity: 1;
}

.navbar-logo {
  height: 50px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  color: white;
  position: relative;
}

.navbar-menu.open {
  display: flex; /* Show menu when toggled */
  flex-direction: column; /* Stack menu items vertically */
  background-color: black; /* Apply background color */
  position: absolute;
  top: 60px; /* Position below navbar */
  left: 0;
  right: 0;
  padding: 20px;
  gap: 10px;
  border-radius: 8px; /* Rounded corners for menu */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Drop shadow */
}

.nav-item {
  cursor: pointer;
  position: relative;
  color: white;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #ffcc00;
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ffcc00;
  border-radius: 10px;
  padding: 10px 0;
  margin-top: 0;
}

.dropdown-content li {
  color: white;
  padding: 5.5px 20px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  background-color: black;
  transition: background-color 0.3s, color 0.3s;
  border-bottom: 1px solid grey;
}

.dropdown-content li:last-child {
  border-bottom: none;
}

.dropdown-content li:hover {
  background-color: #ffcc00;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.apply-now {
  background-color: #ffcc00;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.apply-now::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: red;
  transition: all 0.5s ease;
  border-radius: 50%;
  z-index: 0;
  transform: translate(-50%, -50%) scale(0);
}

.apply-now:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.apply-now:hover {
  background-color: red;
  color: white;
}

.apply-now span {
  position: relative;
  z-index: 1;
}

.contact {
  color: white;
}

.hamburger {
  display: none; /* Hide by default */
  font-size: 24px;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none; /* Hide menu by default on small screens */
  }

  .navbar-menu.open {
    display: flex; /* Show menu when toggled */
    flex-direction: column; /* Stack items vertically */
  }

  .hamburger {
    display: block; /* Show hamburger icon */
  }

  .apply-now, .contact {
    display: none; /* Hide apply now and contact info on small screens */
  }
}
