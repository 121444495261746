.vfx-animation-page {
    background-image: url('https://maacdelhi.com/img/advfx-main-img.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.vfx-content {
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    margin-top: -20%;
    text-align: center;
}

.vfx-content h1 {
    font-size: 3.1em;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: "Atami", sans-serif;
}

.vfx-details {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
}

.vfx-details span {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}

.vfx-about-p {
    width: 100%;
    display: flex;
    justify-content: center;
}

.vfx-about-p p {
    font-size: 1rem;
    color: black;
    width: 80%;
    max-width: 800px;
    margin-left: -30%;
    margin-top: -8%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
}

.vfx-para-B {
    width: 100%;
    display: flex;
    justify-content: center;
}

.vfx-para-B p {
    font-size: 1rem;
    color: black;
    text-align: left;
    margin-left: -40%;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
}

.vfx-software-section {
    width: 100%;
    max-width: 900px;
    text-align: center;
    margin-top: 40px;
    margin-left: 170px;
}

.vfx-software-section h2 {
    font-size: 38px;
    letter-spacing: .5px;
    line-height: 1.2em;
    color: black;
    margin-bottom: 40px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
}

.vfx-software-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.vfx-soft-inr-div {
    background: #FBFBFB;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, .25);
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    width: calc(25% - 20px);
    box-sizing: border-box;
}

.vfx-soft-inr-div img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.vfx-soft-inr-div:hover img {
    transform: scale(1.1);
}

.vfx-soft-inr-div p {
    font-size: 1rem;
    color: black;
    margin: 0;
    font-weight: bold;
}

.vfx-student-works-section {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    margin-top: 40px;
    margin-left: 60px;
    padding-left: 20px;
}

.vfx-student-works-section h2 {
    font-size: 30px;
    letter-spacing: .5px;
    line-height: 1.2em;
    text-align: left;
    font-weight: 900;
    color: black;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 100px;
}

.vfx-student-works-section p {
    font-size: 1.2rem;
    color: #333;
    text-align: left;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: -10px;
}

.vfx-student-works-container {
    display: flex;
    overflow-x: hidden; /* Disable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap;
    position: relative;
    padding: 20px 0; /* Add padding to the container */
    scroll-behavior: smooth; /* Set the scrolling behavior */
    gap: -10px;
}

.vfx-student-works-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
}

.vfx-student-work-item {
    flex: 0 0 auto;
    margin-right: 20px;
    width: auto; /* Set width to auto to adjust according to content */
    padding: 10px; /* Add padding to the student work item */
    border-radius: 5px; /* Add border radius */
    background-color: #fff; /* Add background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.vfx-student-work-item img {
    max-width: 100%;
    border-radius: 5px; /* Apply border radius to images */
    transition: transform 0.3s ease;
}

.vfx-student-work-item:hover img {
    transform: scale(1.05);
}

.vfx-faq-section {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(185, 181, 181, 0.881);
    margin-top: 100px;
}

.vfx-faq-section h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-family: 'Times New Roman', Times, serif;
}

.vfx-faq-item {
    border-bottom: 1px solid #ddd;
}

.vfx-faq-item:last-child {
    border-bottom: none;
}

.vfx-faq-question {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
}

.vfx-faq-question.active {
    background-color: #eaeaea;
}

.vfx-faq-answer {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    font-weight: 600;
    text-align: left;
}

.vfx-dropdown-symbol {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.vfx-faq-question.active .vfx-dropdown-symbol {
    transform: rotate(180deg);
}

/* Responsive styles */

@media (max-width: 1200px) {
    .vfx-content {
        margin-top: -10%;
    }
    .vfx-content h1 {
        font-size: 2.5em;
    }
    .vfx-about-p p, .vfx-para-B p {
        margin-left: 0;
        width: 90%;
    }
    .vfx-software-section {
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    .vfx-content h1 {
        font-size: 2em;
    }
    .vfx-details span {
        font-size: 1.4rem;
    }
    .vfx-about-p p, .vfx-para-B p {
        font-size: 0.9rem;
        width: 100%;
    }
    .vfx-software-section h2 {
        font-size: 32px;
    }
    .vfx-soft-inr-div {
        width: calc(33.33% - 20px); /* Adjust width for three containers per line */
    }
    .vfx-student-works-section, .vfx-faq-section {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .vfx-content h1 {
        font-size: 1.8em;
    }
    .vfx-details span {
        font-size: 1.2rem;
    }
    .vfx-about-p p, .vfx-para-B p {
        font-size: 0.8rem;
    }
    .vfx-soft-inr-div {
        width: calc(50% - 20px); /* Adjust width for two containers per line */
    }
    .vfx-student-works-container {
        padding: 10px 0;
    }
}

@media (max-width: 576px) {
    .vfx-content h1 {
        font-size: 1.5em;
    }
    .vfx-details span {
        font-size: 1rem;
    }
    .vfx-about-p p, .vfx-para-B p {
        font-size: 0.7rem;
    }
    .vfx-soft-inr-div {
        width: 100%; 
    }
    .vfx-software-section h2, .vfx-student-works-section h2 {
        font-size: 24px;
    }
}
