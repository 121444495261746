/* Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.footer-logo img {
  width: 250px;
}

.footer-paragraph {
  flex: 1 1 100%;
  margin: 20px 0;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.footer-column {
  flex: 1 1 30%;
  margin: 10px;
}

.footer-column h3 {
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0px 0px 0px 10px;
}

.footer-column ul li {
  margin: 10px 0;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.social-media {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.social-media li {
  margin-right: 0px;
  justify-content: center;
}

.social-media li a {
  color: #fff;
  text-decoration: none;
}

.abc{
  margin: 2px;
  padding: 2px;
  justify-content: center;
}

@media (max-width: 768px) {
  .footer-column {
    flex: 1 1 100%;
  }
}
